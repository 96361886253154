import React from "react";
import IndexPage from "./index"

const IndexPageHU = () => (
  <IndexPage i18n="hu"/>
);

export default IndexPageHU;


